import React from 'react'

import HomeContainer from 'src/components/Page/Home/container'


const HomePage = () => {
    return <HomeContainer />
}

export default HomePage

